.textInput{
    border:1px solid rgba(0,0,0,.2);
    border-radius:5px;
    box-sizing:border-box;
    font-family:Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size:14px;
    min-height:3.07143em;
    outline:none;
    box-shadow:none;
    padding:.71429em
}
.textInput::-ms-clear,.textInput::-ms-reveal{
    display:none
}
.textInput:focus{
    border:2px solid #00c4cc;
    padding:calc(.71429em - 1px)
}
.textInput--invalid,.textInput--invalid:focus{
    border-color:#ff9500
}
.textInput:disabled,.textInput[readonly]{
    opacity:.5
}
.textInputBlock{
    display:block;
    width:100%
}
.textInputSmall,.textInputSmall:focus{
    border-width:1px;
    min-height:2.14286em;
    padding:.35714em .71429em
}
.marketplace__grid{
    box-sizing:border-box;
    margin-bottom:1.5625em;
    margin-left:-14px;
    margin-right:-14px
}
.marketplace__grid--related{
    margin-top:1.5em
}
.marketplace__gridItem{
    width:33.3%;
    box-sizing:border-box;
    margin-bottom:1.5em;
    padding-left:14px;
    padding-right:14px
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(1) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s .1s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(2) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s .2s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(3) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s .3s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(4) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s .4s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(5) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s .5s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(6) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s .6s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(7) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s .7s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(8) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s .8s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(9) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s .9s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(10) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s 1s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(11) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s 1.1s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(12) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s 1.2s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(13) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s 1.3s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(14) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s 1.4s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(15) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s 1.5s both
}
.marketplace__grid--loaded .marketplace__gridItem:nth-child(n+16) .marketplace__gridItemInner{
    animation:marketplace__gridItemAnimation .5s 1.6s both
}
.marketplace__grid--homepage .marketplace__gridItem{
    width:25%
}
.marketplace__grid--homepage .marketplace__gridItem--fullWidth{
    width:75%
}
@media only screen and (max-width:999px){
    .marketplace__grid--homepage .marketplace__gridItem{
        width:33.33%
    }
    .marketplace__grid--homepage .marketplace__gridItem--fullWidth{
        width:66.66%
    }
}
@media only screen and (max-width:568px){
    .marketplace__grid--homepage .marketplace__gridItem{
        width:50%
    }
}
@media only screen and (max-width:320px){
    .marketplace__grid--homepage .marketplace__gridItem{
        width:100%
    }
}
.marketplace__grid--related .marketplace__gridItem{
    width:33.33%
}
@media only screen and (max-width:568px){
    .marketplace__grid--related .marketplace__gridItem{
        width:50%
    }
}
@media only screen and (max-width:320px){
    .marketplace__grid--related .marketplace__gridItem{
        width:100%
    }
}
.marketplace__gridItemInner{
    position:relative;
    background-color:#fff;
    border-radius:5px;
    overflow:hidden;
    transform:translate3d(0,-20px,0);
    opacity:0;
    box-shadow:0 2px 1px rgba(0,0,0,.08)
}
.marketplace__gridItemInner--noBackground{
    background:none;
    box-shadow:none
}
@keyframes marketplace__gridItemAnimation{
    from{
        opacity:0;
        transform:translate3d(0,-20px,0)
    }
    to{
        opacity:1;
        transform:translate3d(0,0,0)
    }
}
.marketplace__row{
    width:100%;
    max-width:75em;
    padding-left:16px;
    padding-right:16px;
    margin:auto;
    position:relative;
    box-sizing:border-box
}
.marketplace__row:after{
    content:"";
    display:table;
    clear:both
}
@media only screen and (max-width:999px){
    .marketplace__row{
        padding-left:24px;
        padding-right:24px
    }
}
.marketplaceResultGrid__loadSpinner--hidden{
    display:none
}
.marketplace__grid--loaded .marketplace__gridItem .marketplace__gridItemInner.marketplace__gridItemInner--noAnimation{
    animation:none;
    opacity:1;
    transform:translate3d(0,0,0)
}
.marketplaceCard__title{
    color:#000;
    font-size:13px;
    line-height:18px;
    font-weight:600;
    width:100%;
    display:block;
    text-decoration:none
}
.marketplaceCard__title:visited{
    color:#000
}
.marketplaceCard__infoContainer{
    background-color:#fff;
    padding:.75em;
    position:relative;
    border-top:1px solid rgba(0,0,0,.1)
}
.marketplaceCard__category{
    font-size:.8125em;
    line-height:1.38462em;
    font-weight:300;
    margin-bottom:0;
    color:#979797;
    text-decoration:none
}
.marketplaceCard__category:visited{
    color:#979797
}
.marketplaceCard__thumbnail{
    border:0;
    vertical-align:bottom;
    max-width:100%;
    height:auto!important;
    width:100%;
    position:absolute;
    top:0
}
.marketplaceCard__category:hover,.marketplaceCard__category:focus{
    color:#00c4cc
}
.marketplaceCard__thumbnailLink{
    position:relative;
    display:block;
    overflow:hidden
}
.marketplaceCard__thumbnailLink:before{
    content:'';
    z-index:9;
    background-color:#000;
    background-color:rgba(0,0,0,.3);
    background:linear-gradient(transparent,rgba(0,0,0,.5));
    bottom:0;
    height:100px;
    left:0;
    position:absolute;
    right:0;
    transition:opacity .3s;
    opacity:0
}
.marketplaceCard__thumbnailLink:hover:before{
    opacity:1
}
.marketplaceCard__thumbnailLink:after{
    content:attr(data-label);
    z-index:9;
    bottom:1em;
    left:1em;
    right:1em;
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    position:absolute;
    color:#fff;
    transition:opacity .3s,transform .3s;
    transform:translate3d(0,10px,0);
    opacity:0;
    font-size:.75em
}
.marketplaceCard__thumbnailLink:hover:after{
    opacity:1;
    transform:translate3d(0,0,0)
}
.marketplaceSingle{
    background:#ebeced
}
.marketplaceSingle__main{
    width:70%;
    float:left;
    padding-right:1.5625em;
    box-sizing:border-box
}
.marketplaceSingle__sidebar{
    width:30%;
    float:left;
    margin-bottom:25px
}
.marketplaceSingle__mobileButton{
    display:none
}
@media only screen and (max-width:999px){
    .marketplaceSingle__main{
        width:auto;
        float:none;
        padding-right:0
    }
    .marketplaceSingle__sidebar{
        width:auto;
        float:none
    }
    .marketplaceSingle__mobileButton{
        display:block;
        padding:0 2.25em 2.25em
    }
    .marketplaceSingle__sidebarBlock--hideOnMobile{
        display:none
    }
}
.marketplaceSingle__description{
    padding:0 2.25em 2.25em;
    color:#505660;
    font-weight:400
}
.marketplaceSingle__tags{
    padding:0 2.25em 1.75em
}
.marketplaceSingle__tag{
    margin:0 .5em .5em 0;
    text-decoration:none;
    color:#3f4652;
    line-height:1.86;
    border:2px solid #e1e2e4;
    border-radius:5px;
    cursor:pointer;
    display:inline-block;
    font-size:14px;
    padding:0 15px
}
.marketplaceSingle__tag:hover{
    background-color:#f9f9fa
}
.marketplaceSingle__tag:active,.marketplaceSingle__tag:focus{
    border-color:#7f7f7f
}
.marketplaceSingle__imageContainer{
    padding:2.25em
}
.marketplaceSingle__image{
    margin:0 auto;
    display:block;
    max-height:500px;
    box-sizing:border-box;
    max-width:100%
}
.marketplaceSingle__container{
    background-color:#fff;
    border-radius:5px;
    box-shadow:0 2px 1px rgba(0,0,0,.08);
    overflow:hidden
}
.marketplaceSingle__sidebarBlock{
    border-radius:5px;
    box-shadow:0 2px 1px rgba(0,0,0,.08);
    background:#fff;
    overflow:hidden;
    border:1px solid rgba(0,0,0,.1);
    border-bottom:0
}
.marketplaceSingle__sidebarBlock~.marketplaceSingle__sidebarBlock{
    margin-top:1.5625em
}
.marketplaceSingle__sidebarBlock--search{
    overflow:initial
}
.marketplaceSingle__sidebarBlock--noShadow{
    box-shadow:none
}
.marketplaceSingle__title{
    font-size:17px;
    color:#53585f;
    font-weight:400
}
.marketplaceSingle__avatar{
    min-width:50px;
    height:50px;
    width:50px;
    margin-right:1em
}
.marketplaceSingle__avatarImage{
    border-radius:50%;
    border:0;
    vertical-align:bottom;
    max-width:100%;
    height:auto!important;
    width:auto
}
.marketplaceSingle__authorName{
    font-weight:400;
    font-size:.875em;
    line-height:1.42857em
}
.marketplaceSingle__authorCreatedBy{
    color:#b2b2b2;
    display:block
}
.marketplaceSingle__avatarBlock{
    position:relative;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-align:center;
    align-items:center;
    padding:1em
}
.marketplaceSingle__contentBlock{
    padding:1.5em 1.5em .25em
}
.marketplaceSingle__designButton{
    border:2px solid #99c432;
    background-color:#99c432;
    color:#fff;
    display:block;
    background-clip:border-box;
    cursor:pointer;
    text-align:center;
    font-weight:400;
    transition:background-color .3s,color .3s,border .3s;
    padding:13px 3.42857em;
    font-size:14px;
    line-height:1.71429em;
    border-radius:.35714em;
    text-decoration:none
}
.marketplaceSingle__designButton:visited,.marketplaceSingle__designButton:active{
    color:#fff
}
.marketplaceSingle__resultGrid{
    margin-top:30px
}
.comboBox{
    display:-ms-flexbox;
    display:flex;
    -ms-flex-direction:column;
    flex-direction:column;
    position:relative;
    box-shadow:0 0 10px rgba(0,0,0,.15)
}
.comboBox--noShadow{
    box-shadow:none
}
.comboBox__query{
    background-color:white;
    border:none;
    box-sizing:border-box;
    border-radius:5px;
    font-family:inherit;
    font-size:14px;
    min-height:3.07143em;
    outline:none;
    box-shadow:none;
    padding:.71429em .71429em .71429em 37px;
    margin:0
}
.comboBox__query--large{
    box-shadow:0 20px 30px 0 rgba(0,0,0,.1);
    font-size:16px;
    padding:20px 46px 20px 20px
}
.comboBox__query--flat{
    border-bottom-left-radius:0;
    border-bottom-right-radius:0
}
.comboBox:before{
    background:url(https://static.canva.com/static/images/icon_search_gray.2.svg) center no-repeat;
    content:'';
    height:17px;
    position:absolute;
    width:17px;
    top:13px;
    left:.71429em
}
.comboBox--large:before{
    left:auto;
    right:22px;
    top:23px
}
.comboBox__completionsContainer{
    position:relative
}
.comboBox__completionGroups{
    position:absolute;
    z-index:9999;
    width:100%;
    border-top:1px solid #e6e6e6;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    overflow:hidden;
    background-color:white;
    box-shadow:0 4px 10px rgba(0,0,0,.15)
}
.comboBox__completionGroups--hidden{
    display:none
}
.comboBox__completion{
    padding:.71429em;
    cursor:pointer
}
.comboBox__completion--selected{
    background-color:#f2f2f2
}
.comboBox__groupTitleContainer{
    background:#fff url(https://static.canva.com/static/images/1x1-e6e6e6ff.png) repeat-x center;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:distribute;
    justify-content:space-around
}
.comboBox__groupTitle{
    background-color:white;
    padding:.2em 1em;
    text-transform:uppercase;
    color:#a2a2a2;
    font-size:smaller
}
.comboBox__loader{
    min-height:44px;
    transition:.3s opacity;
    background:url(https://static.canva.com/static/images/spinner.png) center / contain no-repeat;
    animation:comboBox__loaderSpin 1s infinite linear;
    width:21px;
    height:21px;
    margin:0 auto
}
.comboBox__loader--hidden{
    position:absolute;
    left:0;
    right:0;
    opacity:0;
    pointer-events:none
}
@keyframes comboBox__loaderSpin{
    from{
        transform:rotate(0deg)
    }
    to{
        transform:rotate(1turn)
    }
}
.marketplaceSearchBox{
    margin-bottom:20px
}
.marketplaceSearchBox--gallery{
    text-align:left;
    max-width:472px;
    margin:30px auto 40px
}
.marketplacePagination{
    width:100%;
    text-align:center;
    color:#636973;
    line-height:1.4em
}
.marketplacePagination__item,.marketplacePagination__link{
    padding:7px 12px;
    margin:0 2px;
    border-radius:5px
}
.marketplacePagination__item--slim{
    padding-left:2px;
    padding-right:2px;
    margin:0
}
.marketplacePagination__item{
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    cursor:default
}
.marketplacePagination__item--active{
    border:1px solid #ccc
}
.marketplacePagination__link{
    transition:.3s background
}
.marketplacePagination__link:hover{
    background:#eee
}
.marketplacePagination__link,.marketplacePagination__link:active,.marketplacePagination__link:visited{
    text-decoration:none;
    color:#636973
}
.relatedQueries{
    margin-bottom:16px
}
.relatedQueries__title{
    color:#888;
    font-size:12px
}
.relatedQueries__list{
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    margin:8px -4px 0
}
.relatedQueries__listItem{
    color:#888;
    font-size:.9em;
    text-decoration:none;
    display:block;
    margin:4px;
    padding:2px 14px;
    border-radius:4px;
    border:1px solid #ccc;
    transition:.3s background
}
.relatedQueries__listItem:hover{
    background-color:#ddd
}
.marketplaceCategoryList{
    margin:0;
    padding:.75em 0;
    background-color:#fff
}
.marketplaceCategoryList--padded{
    padding:1em
}
.marketplaceCategoryList__category{
    list-style:none;
    position:relative;
    font-size:14px
}
.marketplaceCategoryList__category--current>.marketplaceCategoryList__categoryLink{
    font-weight:600;
    color:#000
}
.marketplaceCategoryList__category--dashed{
    padding-left:18px
}
.marketplaceCategoryList__category--dashed:before{
    content:'â€”';
    width:10px;
    position:absolute;
    left:0;
    top:3px
}
.marketplaceCategoryList__categoryLink{
    color:#3f4652;
    line-height:1.85em;
    cursor:pointer;
    text-decoration:none;
    display:block
}
.marketplaceCategoryList__categoryLink:visited{
    color:#3f4652
}
.marketplaceCategoryList__categoryLink:hover,.marketplaceCategoryList__categoryLink:focus{
    color:#292d35
}
.marketplaceCategoryList__children{
    display:none;
    list-style:none;
    transition:1s max-height
}
.marketplaceCategoryList__children--visible{
    display:inherit
}
.marketplaceCategoryList__divider{
    height:1px;
    background-color:#ccc;
    margin:10px 0
}
.loadSpinner{
    width:100%
}
.loadSpinner--floating{
    position:relative
}
.loadSpinner__scaleEffect--floating{
    position:absolute;
    top:0;
    left:0;
    right:0
}
.loadSpinner__scaleEffect{
    width:42px;
    height:42px;
    margin-left:auto;
    margin-right:auto;
    transition:opacity .2s,transform .2s;
    pointer-events:none;
    opacity:0;
    transform:scale(.5)
}
.loadSpinner__scaleEffect--fullSize{
    opacity:1;
    transform:none;
    pointer-events:inherit
}
.loadSpinner__spinner{
    animation:loader__spin 1s infinite linear;
    width:42px;
    height:42px
}
.loadSpinner__spinner--colorful{
    background:url(https://static.canva.com/static/images/spinner.png) center / contain no-repeat
}
.loadSpinner__spinner--paused{
    animation-play-state:paused
}
@keyframes loader__spin{
    from{
        transform:rotate(0deg)
    }
    to{
        transform:rotate(360deg)
    }
}
.marketplaceResultGrid__noResults{
    text-align:center;
    color:#666;
    padding-top:15px
}
.stickyButton{
    display:none
}
@media(max-width:760px){
    .stickyButton{
        display:block;
        position:fixed;
        bottom:0;
        left:3em;
        right:3em;
        z-index:999;
        transform:translateY(100%);
        background-color:#99c432;
        padding:1em;
        border-radius:3.125em;
        text-decoration:none;
        outline:none;
        text-align:center;
        color:#fff;
        font-size:.875em;
        line-height:1.71429em;
        font-weight:400;
        font-family:Open Sans,sans-serif;
        transition:transform .4s ease-in-out
    }
    .stickyButton.stickyButton--show{
        transform:translateY(-50%)
    }
}
.stickyHeader{
    position:fixed;
    width:100%;
    top:0;
    height:72px;
    background:linear-gradient(to right,#00c4cc,#7d2ae8);
    transform:translateY(-100%);
    transition:transform .3s;
    color:white;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-align:center;
    align-items:center;
    -ms-flex-pack:center;
    justify-content:center
}
.stickyHeader__content{
    display:-ms-flexbox;
    display:flex;
    -ms-flex-align:center;
    align-items:center
}
.stickyHeader__heading{
    font-size:18px;
    margin-right:8px
}
.stickyHeader__subheading{
    font-size:18px;
    font-weight:100;
    margin-right:auto
}
.stickyHeader__loginButton,.stickyHeader__signupButton{
    display:block;
    text-align:center;
    line-height:1.71429em;
    border-radius:5px;
    cursor:pointer;
    transition:background-color .3s,color .3s,border .3s;
    text-decoration:none
}
.stickyHeader__loginButton{
    color:white;
    background-color:rgba(0,0,0,.2);
    font-weight:100;
    padding:.72em 2em
}
.stickyHeader__loginButton:hover{
    background-color:rgba(0,0,0,.3)
}
.stickyHeader__signupButton{
    margin-left:16px;
    color:#000;
    background-color:#fff;
    padding:.72em 1.5em
}
.stickyHeader__signupButton:hover{
    background-color:#eee
}
.stickyHeader--show{
    transform:none
}
@media(max-width:760px){
    .stickyHeader{
        display:none
    }
}
@keyframes shake{
    2.5%,22.5%{
        transform:translateX(-1px)
    }
    5%,20%{
        transform:translateX(2px)
    }
    7.5%,12.5%,17.5%{
        transform:translateX(-3px)
    }
    10%,15%{
        transform:translateX(3px)
    }
    25%{
        transform:translateX(0)
    }
}
.marketplaceAppBanner{
    display:none
}
@media(max-width:760px){
    .marketplaceAppBanner{
        background:#3f4652;
        display:-ms-flexbox;
        display:flex;
        -ms-flex-align:center;
        align-items:center
    }
    .marketplaceAppBanner__body{
        text-decoration:none;
        padding:8px;
        display:-ms-flexbox;
        display:flex;
        -ms-flex-align:center;
        align-items:center;
        -ms-flex:1 1 auto;
        flex:1 1 auto;
        text-overflow:ellipsis;
        white-space:nowrap;
        overflow:hidden
    }
    .marketplaceAppBanner__logo{
        width:2.5em;
        height:2.5em;
        animation:shake 3.28s 2s cubic-bezier(.36,.07,.19,.97) infinite;
        background:url(https://static.canva.com/static/images/app_icon_large.png) 0 / contain no-repeat;
        -ms-flex:0 0 auto;
        flex:0 0 auto
    }
    .marketplaceAppBanner__content{
        margin-left:8px;
        font-size:.8125em;
        line-height:1.46154em
    }
    .marketplaceAppBanner__title{
        color:#fff
    }
    .marketplaceAppBanner__subheading{
        color:#b5b9bd
    }
    .marketplaceAppBanner__close{
        width:2em;
        height:2em;
        margin:8px;
        background:rgba(255,255,255,.1);
        border-radius:50%;
        color:#fff;
        cursor:pointer;
        -ms-flex:0 0 auto;
        flex:0 0 auto;
        position:relative
    }
    .marketplaceAppBanner__close:before{
        content:"\00D7";
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        -webkit-font-smoothing:antialiased;
        -moz-osx-font-smoothing:grayscale;
        text-transform:none;
        font-style:normal;
        font-variant:normal;
        font-weight:700
    }
}
.marketplaceCarousel__Container{
    padding:2.25em;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-direction:row;
    flex-direction:row;
    max-height:500px
}
.marketplaceCarousel__previewContainer{
    max-height:inherit;
    width:75%;
    margin:0 16px 0 0
}
.marketplaceCarousel__previewImage{
    object-fit:contain;
    max-height:inherit;
    width:100%;
    height:100%
}
.marketplaceCarousel__thumbnailList{
    list-style-type:none;
    max-height:inherit;
    width:25%;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-flow:column;
    flex-flow:column;
    overflow:auto
}
.marketplaceCarousel__thumbnail{
    width:100%;
    margin:0 0 8px;
    cursor:pointer
}
.marketplaceCarousel__thumbnail:last-child{
    margin:0
}
@media(max-width:760px){
    .marketplaceCarousel__Container{
        max-height:640px;
        -ms-flex-direction:column;
        flex-direction:column
    }
    .marketplaceCarousel__previewContainer{
        width:auto;
        max-height:468px;
        margin:0 0 16px
    }
    .marketplaceCarousel__thumbnailList{
        -ms-flex-flow:row;
        flex-flow:row;
        width:100%
    }
    .marketplaceCarousel__thumbnail{
        max-height:156px;
        height:25vw;
        width:auto;
        margin:0 8px 0 0
    }
}
body.mask2Visible{
    overflow:hidden
}
.mask2{
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    opacity:0;
    overflow:auto;
    pointer-events:none;
    transition:opacity .3s ease;
    z-index:1001
}
.mask2--visible{
    opacity:1;
    pointer-events:auto
}
.mask2--dark{
    background-color:rgba(0,0,0,.4)
}
.mask2Background{
    transition:filter .3s ease
}
.mask2Background--blurred{
    filter:blur(2px)
}
.dialog2__container{
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:center;
    justify-content:center;
    -ms-flex-align:center;
    align-items:center;
    min-width:100%;
    height:100%
}
.dialog2__box{
    margin:22px 0 16px;
    transform:scale(.8);
    transition:transform .3s ease,opacity .3s ease
}
.mask2--visible .dialog2__box{
    transform:scale(1)
}
.dialog2__box--withShadow{
    box-shadow:0 5px 8px 0 rgba(0,0,0,.25)
}
.dialog2__box--withRoundedBorders,.dialog2__box--withRoundedBorders .dialog2__content{
    border-radius:8px
}
.dialog2__closeButton{
    position:absolute;
    display:block;
    top:0;
    right:0;
    padding:0;
    background:none;
    border:none;
    fill:#3f4652;
    width:24px;
    height:24px;
    cursor:pointer;
    outline:none;
    transform:translate(24px,-24px);
    transition:opacity .3s ease;
    z-index:-1
}
.dialog2__box--withRoundedBorders .dialog2__closeButton{
    transform:translate(16px,-16px)
}
.dialog2__content{
    background:#fff;
    overflow:hidden
}
.marketplace_dialog{
    font-weight:100;
    line-height:1.8em;
    background-color:white;
    border-radius:4px;
    box-shadow:0 5px 8px 0 rgba(0,0,0,.25);
    height:400px;
    display:-ms-flexbox;
    display:flex
}
.marketplace_dialogLeft{
    width:320px;
    padding:40px;
    text-align:center;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-pack:distribute;
    justify-content:space-around;
    -ms-flex-align:stretch;
    align-items:stretch;
    -ms-flex-flow:column;
    flex-flow:column
}
.marketplace_dialogHeading{
    font-size:24px;
    margin:16px 0
}
.marketplace_dialogContent{
    font-size:14px;
    margin:16px 0
}
.marketplace_dialogButton{
    margin:16px 8px;
    background-color:#84c84a;
    border:none;
    color:white;
    text-decoration:none;
    padding:8px 32px;
    border-radius:4px;
    font-size:14px
}
.marketplace_dialogRight{
    width:320px;
    border-radius:0 4px 4px 0;
    background:url(https://static.canva.com/static/images/marketplace/dialog/bg.svg) center / cover no-repeat #376ce8
}
.marketplace_dialogImageContainer{
    width:100%;
    height:100%;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-flow:column;
    flex-flow:column;
    -ms-flex-pack:center;
    justify-content:center;
    -ms-flex-align:center;
    align-items:center
}
.marketplace_dialogImage{
    max-height:64%;
    max-width:64%;
    background-color:white;
    border-radius:4px;
    box-shadow:0 5px 8px 0 rgba(0,0,0,.25)
}
.marketplace_dialogImageLabel{
    height:18px;
    -ms-flex-item-align:start;
    align-self:flex-start;
    margin-left:32px
}
.marketplace_dialogImageArrow{
    -ms-flex-item-align:start;
    align-self:flex-start;
    margin-left:40px;
    margin-top:8px
}
.marketplace__container{
    padding-top:1.6em
}
.marketplaceGallery{
    background:#ebeced
}
.marketplaceGallery__footer{
    background:#dee0e2;
    box-sizing:border-box;
    padding-top:28px
}
.marketplaceGallery__contentTitle{
    color:#555;
    font-size:16px;
    margin-bottom:18px;
    font-weight:700
}
.marketplaceGallery__contentDescriptionPara{
    color:#777;
    margin-bottom:28px;
    font-size:14px;
    line-height:1.9em
}
.marketplaceGallery__pagination{
    padding-bottom:1.6em
}
.marketplaceGallery__pagination--hidden{
    display:none
}
.marketplaceSingle__sidebarBlock--hidden{
    display:none
}
