html {
  font-size: 100%;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
  touch-action: manipulation;
}

body {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  overflow-x: hidden;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
  font-weight: inherit;
}

h1,
h2,
h3 {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 3.5em;
  line-height: 1;
}
h2 {
  font-size: 3em;
  line-height: 1.1;
}
h3 {
  font-size: 2.5em;
}
h4 {
  font-size: 1.5em;
}
h5 {
  font-size: 1.2em;
}
h6 {
  font-size: 0.9em;
  letter-spacing: 1px;
}

a,
button {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s;
  line-height: 1;
}

a:focus,
a:active,
a:hover,
button:focus,
button:active,
button:hover,
a b.light-color:hover {
  text-decoration: none;
  color: #333;
}

b {
  font-weight: 500;
}

img {
  width: 100%;
}

li {
  list-style: none;
  display: inline-block;
}

span {
  display: inline-block;
}

button {
  outline: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

b.light-color {
  color: #444;
}

.icon {
  font-size: 1.1em;
  display: inline-block;
  line-height: inherit;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  line-height: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

/* ---------------------------------
2. COMMONS FOR PAGE DESIGN
--------------------------------- */

.center-text {
  text-align: center;
}

.display-table {
  display: table;
  height: 100%;
  width: 100%;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

::-webkit-input-placeholder {
  font-size: 0.9em;
  letter-spacing: 1px;
}

::-moz-placeholder {
  font-size: 0.9em;
  letter-spacing: 1px;
}

:-ms-input-placeholder {
  font-size: 0.9em;
  letter-spacing: 1px;
}

:-moz-placeholder {
  font-size: 0.9em;
  letter-spacing: 1px;
}

.full-height {
  height: 100%;
}

.position-static {
  position: static;
}

.font-white {
  color: #fff;
}

/* ---------------------------------
3. MAIN SECTION
--------------------------------- */

.main-area {
  position: relative;
  z-index: 1;
  height: 100%;
  padding: 0 20px;
  background-size: cover;
  box-shadow: 2px 5px 30px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.main-area:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0.3;
  background: linear-gradient(-9deg, #6a82fb 0%, #6a82fb 40%, #fc5c7d 100%);
}

.main-area .desc {
  margin: 20px auto;
  max-width: 500px;
}

.main-area .notify-btn {
  margin: 20px 0;
  padding: 13px 35px;
  border-radius: 50px;
  border: 2px solid #f84982;
  color: #fff;
  background: #f84982;
}

.main-area .notify-btn:hover {
  background: none;
}

/* EMAIL INPUT */

.main-area .email-input-area {
  margin: 40px auto;
  position: relative;
  width: 450px;
  height: 53px;
}

.main-area .email-input-area .email-input {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 40px;
  border: 0;
  outline: 0;
  padding: 0 140px 0 25px;
  transition: all 0.2s;
  background: #f1f2f3;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1),
    0px 0px 0px 5px rgba(255, 255, 255, 0.3);
  border: 1px solid transparent;
}

.main-area .email-input-area .email-input:focus {
  border-color: #f89fbc;
}

.main-area .email-input-area .submit-btn {
  width: 120px;
  text-align: center;
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  font-size: 0.9em;
  border-radius: 40px;
  transition: all 0.3s;
  background: #0a2846;
  color: #fff;
}

.main-area .email-input-area .submit-btn:hover {
  background: #e40b52;
}

/* TIME COUNTDOWN */

#normal-countdown {
  text-align: center;
}

#normal-countdown .time-sec {
  position: relative;
  display: inline-block;
  margin: 12px;
  height: 90px;
  width: 90px;
  border-radius: 100px;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
  background: #fff;
  color: #333;
}

#normal-countdown .time-sec .main-time {
  font-weight: 500;
  line-height: 70px;
  font-size: 2em;
  color: #f84982;
}

#normal-countdown .time-sec span {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9em;
  font-weight: 600;
}

/* SOCIAL BTN */

.main-area .social-btn {
  position: absolute;
  bottom: 30px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.main-area .social-btn .list-heading {
  display: block;
  margin-bottom: 15px;
}

.main-area .social-btn > li > a > i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  border-radius: 40px;
  font-size: 1.04em;
  margin: 0 5px;
}

.main-area .social-btn > li > a > i:hover {
  background: #fff !important;
}

.main-area .social-btn > li > a > i[class*="facebook"] {
  background: #2a61d6;
}
.main-area .social-btn > li > a > i[class*="twitter"] {
  background: #3aa4f8;
}
.main-area .social-btn > li > a > i[class*="google"] {
  background: #f43846;
}
.main-area .social-btn > li > a > i[class*="instagram"] {
  background: #8f614a;
}
.main-area .social-btn > li > a > i[class*="pinterest"] {
  background: #e1c013;
}

/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
}

/* Screens Resolution : 992px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 992px) {
}

/* Screens Resolution : 767px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 767px) {
  /* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

  p {
    line-height: 1.4;
  }

  h1 {
    font-size: 2.8em;
    line-height: 1;
  }
  h2 {
    font-size: 2.2em;
    line-height: 1.1;
  }
  h3 {
    font-size: 1.8em;
  }

  /* ---------------------------------
	3. MAIN SECTION
	--------------------------------- */

  .main-area {
    padding-bottom: 80px;
  }

  .main-area .email-input-area {
    margin: 5% auto;
    width: 90%;
    height: 47px;
  }

  .main-area .email-input-area .email-input {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 40px;
    border: 0;
    outline: 0;
    padding: 0 95px 0 15px;
  }

  .main-area .email-input-area .submit-btn {
    width: 80px;
    text-align: center;
    position: absolute;
    top: 5px;
    bottom: 5px;
    right: 5px;
  }

  /* TIME COUNTDOWN */

  #normal-countdown .time-sec {
    height: 70px;
    width: 70px;
    margin: 7px;
  }

  #normal-countdown .time-sec .main-time {
    line-height: 55px;
    font-size: 1.8em;
  }

  #normal-countdown .time-sec span {
    bottom: 12px;
  }
}

/* Screens Resolution : 479px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 479px) {
  /* ---------------------------------
	1. PRIMARY STYLES
	--------------------------------- */

  body {
    font-size: 12px;
  }

  /* ---------------------------------
	3. MAIN SECTION
	--------------------------------- */

  /* TIME COUNTDOWN */

  #normal-countdown .time-sec {
    height: 60px;
    width: 60px;
  }

  #normal-countdown .time-sec .main-time {
    line-height: 45px;
    font-size: 1.5em;
  }
}

/* Screens Resolution : 359px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 359px) {
}

/* Screens Resolution : 290px
-------------------------------------------------------------------------- */
@media only screen and (max-width: 290px) {
}

.main-area {
  background: linear-gradient(to right, #00c4cc, #7d2ae8);
}
.main-area::after {
  background-image: url(./../img/firewatch-mod-v2-optimized.jpg);
  background-size: cover;
  opacity: 0.2;
}
