html,
body,
#root,
#App,
#App > div {
  height: 100%;
}
.App {
  background: linear-gradient(to right, #00c4cc, #7d2ae8);
  color: white;
  min-height: 100%;
}
.App:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0.3;
  background: linear-gradient(-9deg, #6a82fb 0%, #6a82fb 40%, #fc5c7d 100%);
}
.App::after {
  background-image: url(./../img/firewatch-mod-v2-optimized.jpg);
  background-size: cover;
  opacity: 0.2;
}

ul {
  padding: 0;
}

.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */

  border-radius: 0;
  background-color: #5a6268;
  color: #fff;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card.selected {
  background-color: #ffc107;
  color: #333;
  box-shadow: 0px 0px 30px #ffc107;
}

.card.disabled {
  opacity: 0.4;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #266db6;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 30px #505050b3;
}

.glyphicon-refresh-animate {
  -animation: spin 0.7s infinite linear;
  -webkit-animation: spin2 0.7s infinite linear;
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
