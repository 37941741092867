.navigationMenu__items{
    list-style:none;
    display:none
}
.navigationMenu__items--desktopEnabled{
    display:-ms-flexbox;
    display:flex;
    padding:0;
    margin:0
}
.navigationMenu__label,.navigationMenu__label:hover,.navigationMenu__label:visited{
    background:none;
    font-size:14px;
    display:inline-block;
    color:rgba(255,255,255,.8);
    padding:10px;
    text-decoration:none;
    border-radius:5px
}
.navigationMenuMobile__overlay{
    background:rgba(41,45,53,.5);
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    opacity:0;
    pointer-events:none;
    z-index:999;
    transition:.4s opacity
}
.navigationMenuMobile__overlay--open{
    opacity:1;
    pointer-events:auto
}
.navigationMenuMobile__button{
    display:none;
    color:white;
    font-size:14px;
    border:1px solid #b5b9bd;
    padding:10px 14px;
    border-radius:2px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    cursor:pointer
}
.navigationMenuMobile__button--mobileEnabled{
    display:block
}
.navigationMenuMobile__items{
    box-sizing:border-box;
    font-size:16px;
    display:none;
    position:fixed;
    top:0;
    bottom:0;
    left:100%;
    width:90%;
    max-width:300px;
    overflow-x:hidden;
    overflow-y:scroll;
    padding:4px 24px 24px;
    background:#292d35;
    transform:translateX(0);
    z-index:1000;
    -ms-flex-flow:column;
    flex-flow:column;
    transition:.3s all
}
.navigationMenuMobile__items--open{
    transform:translateX(-100%)
}
.navigationMenuMobile__items--mobileEnabled{
    display:-ms-flexbox;
    display:flex
}
.navigationMenuMobile__wrapper{
    position:relative
}
.navigationMenuMobile__label,.navigationMenuMobile__label:visited{
    display:block;
    position:relative;
    width:100%;
    box-sizing:border-box;
    padding:12px 0;
    text-decoration:none;
    color:#fff;
    opacity:1;
    cursor:pointer
}
.navigationMenuMobile__label{
    transition:.3s all
}
.navigationMenuMobile__label--hidden{
    opacity:0;
    transform:translateX(-100%);
    pointer-events:none
}
.navigationMenuMobile__label--noAnimation{
    transition:none
}
.navigationMenuMobile__content{
    display:block;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    list-style:none;
    opacity:1;
    transform:translateX(0);
    transition:.3s all
}
.navigationMenuMobile__content--hidden{
    opacity:0;
    transform:translateX(100%) translateX(25px);
    pointer-events:none;
    height:calc(100vh - 100px);
    overflow:hidden
}
.navigationMenuMobile__content--noAnimation{
    transition:none
}
.navigationMenuMobile__content--topLevel{
    top:20px
}
.navigationMenuMobile__contentItem{
    -ms-flex-item-align:start;
    align-self:flex-start;
    width:100%
}
.navigationMenuMobile__label--goUp,.navigationMenuMobile__label--goUp:visited{
    margin-top:-5px;
    padding:12px 0 12px 16px;
    text-transform:uppercase;
    font-size:13px;
    color:rgba(255,255,255,.4);
    box-sizing:border-box
}
.navigationMenuMobile__label--goUp:after{
    content:'';
    position:absolute;
    top:50%;
    z-index:100;
    left:0;
    margin-top:-4px;
    height:0;
    width:0;
    border:4px solid transparent;
    border-right-color:rgba(255,255,255,.4)
}
.navigationMenuMobile__label--hasChildren{
    will-change:transform
}
.navigationMenuMobile__label--hasChildren:after{
    content:'';
    position:absolute;
    top:50%;
    z-index:100;
    right:0;
    margin-top:-4px;
    height:0;
    width:0;
    border:4px solid transparent;
    border-left-color:#888fa0;
    opacity:1;
    transition:.2s opacity
}
.navigationMenuMobile__label--hidden:after{
    opacity:0
}
.navigationMenuMobile__signupButton{
    display:none;
    background-color:#99c432;
    color:#fff;
    width:100%;
    padding:10px 21px;
    font-size:14px;
    line-height:24px;
    border-radius:5px;
    background-clip:border-box;
    cursor:pointer;
    text-align:center;
    text-decoration:none;
    box-sizing:border-box
}
.navigationMenuMobile__signupButton:hover{
    background-color:#9fc14e
}
.navigationMenuDropdown{
    position:relative;
    display:block
}
.navigationMenuDropdown__content{
    display:none;
    position:absolute;
    left:50%;
    padding:0;
    margin-top:15px;
    opacity:0;
    pointer-events:none;
    z-index:1;
    border-radius:8px;
    background:#fff;
    box-shadow:0 5px 8px rgba(0,0,0,.25);
    list-style:none;
    transition:opacity .3s ease,top .2s ease,bottom .2s ease;
    white-space:nowrap;
    box-sizing:border-box
}
.navigationMenuDropdown__content--displayBelowAnchor{
    bottom:13px;
    transform:translate(-50%,0)
}
.navigationMenuDropdown__content--displayAboveAnchor{
    top:9px;
    margin-top:0;
    transform:translate(-50%,-100%)
}
.navigationMenuDropdown__content--show{
    display:block
}
.navigationMenuDropdown__content--open{
    opacity:1;
    pointer-events:auto
}
.navigationMenuDropdown__content--open.navigationMenuDropdown__content--displayBelowAnchor{
    bottom:-11px
}
.navigationMenuDropdown__content--open.navigationMenuDropdown__content--displayAboveAnchor{
    top:-15px
}
.navigationMenuDropdown__scrollInner{
    list-style:none;
    padding:15px 20px 20px;
    margin:5px 0 0;
    overflow-y:auto;
    max-height:75vh
}
.navigationMenuDropdown__button{
    font-size:14px;
    color:#868686;
    text-decoration:none;
    display:inline-block;
    padding:5px;
    width:100%;
    box-sizing:border-box
}
.navigationMenuDropdown__item>.navigationMenuDropdown__button{
    min-width:125px
}
.navigationMenuDropdown__button:hover,.navigationMenuDropdown__button:visited:hover{
    color:#444
}
.navigationMenuDropdown__button:visited{
    color:#868686
}
.navigationMenuDropdown__button:active{
    color:#222
}
.navigationMenuDropdown__button--selected{
    position:relative;
    padding-right:25px
}
.navigationMenuDropdown__button--selected,.navigationMenuDropdown__button--selected:hover,.navigationMenuDropdown__button--selected:visited{
    color:#444
}
.navigationMenuDropdown__button--selected:after{
    content:'';
    background-image:url(https://static.canva.com/static/images/dropdown_tick.svg);
    background-repeat:no-repeat;
    background-size:cover;
    background-position:50% 50%;
    width:20px;
    height:16px;
    position:absolute;
    right:0
}
.navigationMenuDropdown__label:before{
    content:'';
    width:6px;
    height:6px;
    position:absolute;
    left:50%;
    margin-left:-6px;
    box-sizing:border-box;
    border:6px solid transparent;
    opacity:0;
    z-index:10;
    transition:opacity .3s ease,top .2s ease,bottom .2s ease
}
.navigationMenuDropdown__label--displayBelowAnchor:before{
    bottom:13px;
    border-bottom-color:#fff
}
.navigationMenuDropdown__label--displayAboveAnchor:before{
    top:9px;
    border-top-color:#fff
}
.navigationMenuDropdown__label--open:before{
    opacity:1
}
.navigationMenuDropdown__label--open.navigationMenuDropdown__label--displayBelowAnchor:before{
    bottom:-11px
}
.navigationMenuDropdown__label--open.navigationMenuDropdown__label--displayAboveAnchor:before{
    top:-15px
}
.navigationMenuDropdown__label--noContent:before{
    visibility:hidden
}
.navigationMenuDropdown__mega{
    padding:15px 20px 20px;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-direction:row;
    flex-direction:row;
    -ms-flex-wrap:nowrap;
    flex-wrap:nowrap;
    -ms-flex-pack:start;
    justify-content:flex-start;
    -ms-flex-align:stretch;
    align-items:stretch;
    -ms-flex-line-pack:stretch;
    align-content:stretch;
    border-radius:3px;
    overflow:hidden;
    list-style:none;
    box-sizing:border-box
}
.navigationMenuDropdown__item--large:not(:first-child){
    margin-left:64px
}
.navigationMenuDropdown__heading,.navigationMenuDropdown__heading:visited{
    display:block;
    width:100%;
    font-size:16px;
    padding:18px 17px 0;
    color:#3f4652;
    text-decoration:none;
    font-weight:600;
    box-sizing:border-box
}
.navigationMenuDropdown__subitems{
    padding:13px;
    display:block;
    white-space:nowrap
}
.navigationMenuDropdown__subitem{
    display:block;
    list-style:none;
    width:50%
}
.marketplaceHeader{
    width:100%;
    background:linear-gradient(to right,#00c4cc,#7d2ae8);
}
.marketplaceHeader::after{
    background-image:url(./../img/firewatch-mod-v2-optimized.jpg);
    background-size: cover;
    opacity: 0.2;
}
.marketplaceHeader__wrapper{
    display:-ms-flexbox;
    display:flex;
    padding-top:12px;
    padding-bottom:12px;
    -ms-flex-align:center;
    align-items:center
}
.marketplaceHeader__logoWrapper{
    display:-ms-flexbox;
    display:flex;
    -ms-flex-align:center;
    align-items:center;
    -ms-flex-positive:1;
    flex-grow:1;
    text-decoration:none
}
.marketplaceHeader__button{
    display:block;
    color:#000;
    font-weight:400;
    font-size:.875em;
    text-align:center;
    line-height:1.71429em;
    border-radius:.35714em;
    border:2px solid #fff;
    background-color:#fff;
    background-clip:border-box;
    padding:.75em 1.5em;
    cursor:pointer;
    transition:background-color .3s,color .3s,border .3s;
    text-decoration:none
}
.marketplaceHeader__button:focus,.marketplaceHeader__button:active,.marketplaceHeader__button:visited{
    color:#000;
    border-color:#f2f2f2
}
.marketplaceHeader__button:hover{
    border-color:#f2f2f2;
    background-color:#f2f2f2
}
.marketplaceHeader__logoGraphic{
    width:3.75em;
    height:3.75em;
    background-image: url(./../img/online_bankomat_logo.svg);
    background-color: white;
    border-radius: 50px;
    background-size: cover;
    background-position: 0px 3px;
}
.marketplaceHeader__logoName{
    margin-left:.75em;
    color:#fff;
    font-weight:400;
    white-space:nowrap
}
.marketplaceHeader__signin{
    margin-left:1.5em
}
.marketplaceHeader__title{
    color:#fff;
    font-size:48px;
    line-height:1.16667em;
    margin-top:0;
    padding-top:0;
    padding-bottom:0;
    margin-bottom:.5em;
    font-weight:300
}
.marketplaceHeader__banner{
    text-align:center;
    padding-top:35px;
    padding-bottom:30px
}
.marketplaceHeader__bannerHeading{
    color:#fff;
    font-weight:300;
    font-size:40px;
    margin-bottom:24px
}
.marketplaceHeader__bannerSubHeading{
    max-width:650px;
    margin:0 auto;
    color:#fff;
    font-size:18px;
    line-height:26px;
    font-weight:300
}
.marketplaceHeader__bannerPromotionText{
    font-size:16px;
    color:white;
    font-weight:100;
    transform:translateY(-50%)
}
.marketplaceHeader__bannerPromotionLink,.marketplaceHeader__bannerPromotionLink:visited{
    text-decoration:none;
    color:white
}
.marketplaceHeader__bannerSignupCta{
    margin:20px 0;
    display:inline-block;
    padding:10px 3.42857em
}
.navigationMenu__label,.navigationMenu__label:hover,.navigationMenu__label:visited{
    font-size:16px;
    font-weight:400
}
@media(max-width:500px){
    .marketplaceHeader__logoWrapper{
        padding:1em 0
    }
    .navigationMenu__items{
        position:absolute;
        clip:rect(0 0 0 0)
    }
}
@media(max-width:700px){
    .marketplaceHeader__banner{
        padding-top:25px;
        padding-bottom:25px
    }
    .marketplaceHeader__flexSpace{
        display:none
    }
}
@media(max-width:900px){
    .navigationMenu__label,.navigationMenu__label:hover,.navigationMenu__label:visited{
        font-size:14px
    }
    .marketplaceHeader__logoGraphic{
        width:2.75em;
        height:2.75em
    }
    h1{
        font-size:35px
    }
}
@media(max-width:950px){
    .marketplaceHeader__logoName{
        display:none
    }
}
@media(max-width:1050px){
    .marketplaceHeader__signin{
        display:none
    }
}

